import API from '../components/API';
import { adminSelectEmployee } from './admin';
import { getLogout } from './logout';
import { progressStart, progressComplete } from './progress';

export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';

export const getEmployeeRequest = () => ({
  type: GET_EMPLOYEE_REQUEST
});

export const getEmployeeSuccess = employee => ({
  type: GET_EMPLOYEE_SUCCESS,
  payload: employee
});

export const getEmployeeFailure = error => ({
  type: GET_EMPLOYEE_FAILURE,
  payload: { error }
});

export const getEmployee = idToken => {
  return async dispatch => {
    dispatch(progressStart());
    dispatch(getEmployeeRequest());
    const client = API(idToken);
    await client
      .get('/employee')
      .then(response => {
          dispatch(getEmployeeSuccess(response.data));
          dispatch(adminSelectEmployee(response.data));
          dispatch(progressComplete());
      })
      .catch(error => {
        dispatch(progressComplete());
        dispatch(getEmployeeFailure(error));
        dispatch(getLogout());
      });
  };
};
