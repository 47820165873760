import React, { Component } from 'react';
import { connect } from "react-redux";
import FirebaseInstance from '../Firebase';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { createErrorSnackbar, createSuccessSnackbar } from '../../actions/snackbar';

import { ErrorMessage } from 'formik';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiptFile: null,
      url: '',
      progress: 0
    };
    this.onDrop = this.onDrop.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const receiptFile = acceptedFiles[0];
    this.setState(() => ({ receiptFile }));

    var reader = new FileReader();
    reader.onload = function(e) {
        if (e.target.result.startsWith('data:application/pdf')) {
            document.getElementById('previewImg').src = 'assets/pdf-icon.png';
        } else {
            document.getElementById('previewImg').src = e.target.result;
        }
    };
    reader.readAsDataURL(receiptFile);
    this.handleUpload();
  };

  handleUpload = () => {
    const { receiptFile } = this.state;
    const uuid = uuidv4();
    const ext = receiptFile.type === 'application/pdf' ? '.pdf' : '.jpg';
    const fileName = this.props.employee.auth_id + '/' + uuid + ext;
    const uploadTask = FirebaseInstance.storage.ref(`receipts/${fileName}`).put(receiptFile);
    uploadTask.on(
      'state_changed',
      snapshot => {
        // progress function ....
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        this.setState({ progress });
      },
      error => {
        console.error(error);
        this.props.dispatch(createErrorSnackbar(error.message));
      },
      () => {
        // complete function ....
        this.props.dispatch(createSuccessSnackbar('Photo Upload Complete!'));
        FirebaseInstance.storage
          .ref('receipts')
          .child(fileName)
          .getDownloadURL()
          .then(url => {
            this.setState({ url });
            this.props.setFieldValue('receipt_url', url);
          });
      }
    );
  };

  render() {
    const { handleChange } = this.props;
    const style = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    };

    return (
      <div style={style}>
        <Dropzone onDrop={this.onDrop} accept="application/pdf, image/png, image/gif, image/jpg, image/jpeg">
          {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
            // const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
            return (
              <div {...getRootProps()}>
                <input id="file" name="file" {...getInputProps()} />
                <div className="inputDiv">
                  {!isDragActive && 'Drop a receipt image here or click here to select one!'}
                  {isDragActive && !isDragReject && "Drop it like it's hot!"}
                  {isDragReject && 'File type not accepted, sorry!'}
                  <br />
                  <img
                    id="previewImg"
                    className="img-upload"
                    alt=""
                    src={this.state.receiptFile ? this.state.receiptFile.preview : ''}
                  />
                </div>
              </div>
            );
          }}
        </Dropzone>

        <br />

        <br />
        <progress value={this.state.progress} max="100" />
        <Typography variant="body1" align="center">
          {this.state.progress}%
        </Typography>
        <br />

        <TextField
          type="hidden"
          key="receipt_url"
          name="receipt_url"
          id="receipt_url"
          value={this.state.url}
          onChange={handleChange}
        />
        <ErrorMessage name="receipt_url">
          {msg => (
            <div
              style={{
                color: 'red',
                width: '75%',
                textAlign: 'center'
              }}
            >
              {msg}
            </div>
          )}
        </ErrorMessage>
      </div>
    );
  }
}

export default connect()(FileUpload);
