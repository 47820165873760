import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from '../actions/login';
import { LOGOUT_SUCCESS } from '../actions/logout';
const INITIAL_STATE = {
  loginSuccess: false,
};

export default function loginReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return INITIAL_STATE;

    case LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: true,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        loginSuccess: false,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        loginSuccess: false,
      };

    default:
      return state;
  }
}
