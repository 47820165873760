import React, { Component } from 'react';
import ApproveExpensesHeader from './ApproveExpensesHeader';
import ApproveExpensesTable from './ApproveExpensesTable';

class ApproveExpenses extends Component {
  render() {
    return (
      <div className="page">
        <ApproveExpensesHeader idToken={this.props.idToken} />
        <ApproveExpensesTable idToken={this.props.idToken} />
      </div>
    );
  }
}

export default ApproveExpenses;
