import API from '../components/API';
import { createErrorSnackbar } from './snackbar';
import { progressStart, progressComplete } from './progress';

export const download = (idToken, fromDate, toDate) => {
  const params = { fromDate, toDate };
  return dispatch => {
    const client = API(idToken);
    dispatch(progressStart());
    client
      .get('/allExpenses', { params: params })
      .then(response => {
        const expenses = standardize(response.data);
        CsvConverter(expenses, new Date().toLocaleDateString('en-US'));
        dispatch(progressComplete());
      })
      .catch(error => {
        console.error(error);
        dispatch(createErrorSnackbar(error.message));
        dispatch(progressComplete());
      });
  };
};

// Ensures that older data items have all the newer columns
const standardize = expenses => {
  return expenses.map(expense => {
    const result = {
      ...expense,
      admin_approve_date: expense.admin_approve_date === undefined ? '' : expense.admin_approve_date,
      receipt_url: expense.receipt_url === undefined ? '' : expense.receipt_url,
    };
    return result;
  });
};

const CsvConverter = (data, title) => {
  let csv = title + '\r\n\n';
  let headerRow = [];
  let csvRow = '';

  if (data && data.length) {
    Object.keys(data[0]).sort().forEach(key => {
      csvRow += key + ',';
      headerRow.push(key);
    });

    csvRow = csvRow.slice(0, -1);
    csv += csvRow + '\r\n';

    data.forEach(row => {
      let csvRow = '';
      headerRow.forEach(header => {
        csvRow += '"' + row[header] + '",';
      });
      csvRow.slice(0, -1);
      csv += csvRow + '\r\n';
    });
  }

  let fileName = 'Expense_Report_';
  fileName += title.replace(/ /g, '_');
  const uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  const link = document.createElement('a');
  link.href = uri;
  link.download = fileName + '.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
