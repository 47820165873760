import React from 'react';
import Typography from '@material-ui/core/Typography';

const Loading = ({ loadMessage }) => (
  <div style={{ marginTop: 80 }}>
    <Typography variant="h6" align="center" color="secondary">
      {loadMessage || 'Loading...'}
    </Typography>
  </div>
);

export default Loading;
