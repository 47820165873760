import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux';
import { adminSelectYear } from '../../actions/admin';
import { employeeSelectYear } from '../../actions/expenses';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class SelectYear extends React.Component {
  constructor(props) {
    super(props);
    this.currentYear = new Date().getFullYear();
    this.prevYear = this.currentYear - 1;
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    const selectedYear = event.target.value;
    if (this.props.admin) {
      this.props.dispatch(adminSelectYear(selectedYear));
    } else {
      this.props.dispatch(employeeSelectYear(selectedYear));
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <InputLabel>Year</InputLabel>

            <Select
              value={this.props.selectedYear}
              onChange={this.handleChange}
              name="selectedYear"
              className={classes.selectEmpty}
            >
              <MenuItem key={this.currentYear} value={this.currentYear}>
                {this.currentYear}
              </MenuItem>
              <MenuItem key={this.prevYear} value={this.prevYear}>
                {this.prevYear}
              </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </div>
    );
  }
}

SelectYear.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect()(withStyles(styles)(SelectYear));
