import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CustomTableCell from '../CustomTableCell';
import Currency from '../Currency';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReceiptDialog from '../ReceiptDialog';
import UpArrow from '@material-ui/icons/ArrowDropUp';
import DownArrow from '@material-ui/icons/ArrowDropDown';
import Check from '@material-ui/icons/Check';
import { connect } from 'react-redux';

import orderBy from 'lodash/orderBy';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 400
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
});

const reimbursement_source = type => {
  if (type === 'COIN_CRAFT_UNTAXABLE') {
    return 'Company Benefit';
  } else if (type === 'COIN_CRAFT_TAXABLE') {
    return 'Taxable Income';
  } else if (type === 'CREDIT') {
    return 'Credit';
  } else {
    return 'Not Coin+Craft';
  }
};

const employeeTableColumns = [
  { id: 'reimbursement_source', label: 'Expense Type', width: '50pt' },
  { id: 'submitted_date', label: 'Submit Date', width: '50pt' },
  { id: 'approved_by', label: 'Approved By', width: '40pt' },
  { id: 'expense_business_name', label: 'Paid To', width: '60pt' },
  { id: 'expense_amount', label: 'Receipt Total', width: '50pt' },
  { id: 'is_preferred_business', label: 'Preferred Business', width: '40pt' },
  { id: 'miles_amount', label: 'Total Miles', width: '35pt' },
  { id: 'client_name', label: 'Client', width: '70pt' },
  { id: 'expense_description', label: 'Description', width: '160pt' },
  { id: 'receipt_url', label: 'Receipt', width: '30pt' },
  { id: 'admin_approve_date', label: 'Admin Approved', width: '60pt' }
];

const toggleDirection = {
  asc: 'desc',
  desc: 'asc'
};

class EmployeeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnToSort: 'submitted_date',
      sortDirection: 'desc'
    };
  }

  handleSort = columnId => event => {
    this.setState(state => ({
      columnToSort: columnId,
      sortDirection: state.columnToSort === columnId ? toggleDirection[state.sortDirection] : 'asc'
    }));
  };

  filteredExpenses = () => {
    return this.props.expenses.filter(expense => {
      const expenseDate = expense.submitted_date;
      if (!expenseDate.startsWith(this.props.selectedYear)) {
        return false;
      }
      if (this.props.selectedMonth === 0) {
        return true;
      }
      const expenseMonth = Number(expenseDate.split('-')[1]);
      return expenseMonth === this.props.selectedMonth;
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {employeeTableColumns.map(
                  column => (
                    <CustomTableCell
                      key={column.id}
                      align="left"
                      onClick={this.handleSort(column.id)}
                    >
                      <div style={{ display: 'flex', width: column.width }}>
                        <span>{column.label}</span>
                        {this.state.columnToSort === column.id ? (
                          this.state.sortDirection === 'asc' ? (
                            <UpArrow />
                          ) : (
                            <DownArrow />
                          )
                        ) : null}
                      </div>
                    </CustomTableCell>
                  ),
                  this
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {orderBy(this.filteredExpenses(), this.state.columnToSort, this.state.sortDirection).map(n => (
                <TableRow className={classes.row} key={n.expense_id}>
                  <CustomTableCell component="th" scope="row">
                    {reimbursement_source(n.reimbursement_source)}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {new Date(n.submitted_date).toLocaleDateString('en-US')}
                  </CustomTableCell>
                  <CustomTableCell align="left">{n.approved_by}</CustomTableCell>
                  <CustomTableCell align="left" style={{overflowWrap: 'break-word' }}>
                    {n.expense_business_name ? n.expense_business_name : 'N/A'}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    <Currency dollars={n.expense_amount} />
                    <br />
                    <div style={{ color: 'grey' }}>
                      {n.receipt_date ? new Date(n.receipt_date).toLocaleDateString('en-US') : 'N/A'}
                    </div>
                  </CustomTableCell>
                  <CustomTableCell style={{paddingLeft: '30px', overflowWrap: 'break-word' }}>
                    {n.is_preferred_business ? <Check/> : ''}
                  </CustomTableCell>
                  <CustomTableCell align="left">{n.miles_amount}</CustomTableCell>
                  <CustomTableCell align="left">{n.client_name ? n.client_name : 'N/A'}</CustomTableCell>
                  <CustomTableCell align="left" style={{overflowWrap: 'break-word' }}>
                    {n.expense_description}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {n.receipt_url &&
                        (<ReceiptDialog
                            receipt_date={n.receipt_date}
                            amount={n.expense_amount}
                            receiptUrl={n.receipt_url}
                          />)}
                  </CustomTableCell>
                  <CustomTableCell style={{ paddingLeft: '30px' }}>
                  {n.admin_approve_date && n.admin_approve_date !== '' ? <Check/> : ''}
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

EmployeeTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    expenses: state.expensesState.expenses,
    selectedYear: state.expensesState.selectedYear,
    selectedMonth: state.expensesState.selectedMonth
  };
};

export default connect(mapStateToProps)(withStyles(styles)(EmployeeTable));
