import API from '../components/API';
import { createErrorSnackbar, createSuccessSnackbar } from './snackbar';
import { progressStart, progressComplete } from './progress';
import { getDateStringParamsForSelectedMonthAndYear } from './actionUtils';

export const ADMIN_GET_EXPENSES_REQUEST = 'ADMIN_GET_EXPENSES_REQUEST';
export const ADMIN_GET_EXPENSES_SUCCESS = 'ADMIN_GET_EXPENSES_SUCCESS';
export const ADMIN_GET_EXPENSES_FAILURE = 'ADMIN_GET_EXPENSES_FAILURE';

export const getAdminExpensesRequest = () => ({
  type: ADMIN_GET_EXPENSES_REQUEST
});

export const getAdminExpensesSuccess = resp => ({
  type: ADMIN_GET_EXPENSES_SUCCESS,
  payload: { resp }
});

export const getAdminExpensesFailure = error => ({
  type: ADMIN_GET_EXPENSES_FAILURE,
  payload: { error }
});

export const getAdminExpenses = (idToken, selectedEmployee, selectedYear, selectedMonth) => {
  const params = getDateStringParamsForSelectedMonthAndYear(selectedYear, selectedMonth);
  const employee_auth_id = selectedEmployee.auth_id;
  return async dispatch => {
    dispatch(progressStart());
    dispatch(getAdminExpensesRequest());
    const client = API(idToken);
    await client
      .get('/expenses/' + employee_auth_id, { params: params })
      .then(response => {
        dispatch(getAdminExpensesSuccess(response));
          dispatch(progressComplete());
      })
      .catch(error => {
        dispatch(progressComplete());
        dispatch(getAdminExpensesFailure(error));
        dispatch(createErrorSnackbar(error.message));
      });
  };
};

// ---------------------------------------------------------------------

export const ADMIN_GET_EMPLOYEES_REQUEST = 'ADMIN_GET_EMPLOYEES_REQUEST';
export const ADMIN_GET_EMPLOYEES_SUCCESS = 'ADMIN_GET_EMPLOYEES_SUCCESS';
export const ADMIN_GET_EMPLOYEES_FAILURE = 'ADMIN_GET_EMPLOYEES_FAILURE';

export const getAdminEmployeesRequest = () => ({
  type: ADMIN_GET_EMPLOYEES_REQUEST
});

export const getAdminEmployeesSuccess = resp => ({
  type: ADMIN_GET_EMPLOYEES_SUCCESS,
  payload: { resp }
});

export const getAdminEmployeesFailure = error => ({
  type: ADMIN_GET_EMPLOYEES_FAILURE,
  payload: { error }
});

export const getAdminEmployees = idToken => {
  return async dispatch => {
      dispatch(progressStart());
      dispatch(getAdminEmployeesRequest());
    const client = API(idToken);
    await client
      .get('/employees')
      .then(response => {
        dispatch(getAdminEmployeesSuccess(response));
        dispatch(progressComplete());
      })
      .catch(error => {
        dispatch(progressComplete());
        dispatch(getAdminEmployeesFailure(error));
        dispatch(createErrorSnackbar(error.message));
      });
  };
};

// ---------------------------------------------------------------------

export const ADMIN_SELECT_YEAR = 'ADMIN_SELECT_YEAR';
export const ADMIN_SELECT_MONTH = 'ADMIN_SELECT_MONTH';
export const ADMIN_SELECT_EMPLOYEE = 'ADMIN_SELECT_EMPLOYEE';

export const adminSelectYear = year => ({
    type: ADMIN_SELECT_YEAR,
    payload: year
});

export const adminSelectMonth = month => ({
    type: ADMIN_SELECT_MONTH,
    payload: month
});

export const adminSelectEmployee = employee => ({
    type: ADMIN_SELECT_EMPLOYEE,
    payload: employee
});

// ---------------------------------------------------------------------

export const ADMIN_UPDATE_EXPENSE_REQUEST = 'ADMIN_UPDATE_EXPENSE_REQUEST';
export const ADMIN_UPDATE_EXPENSE_SUCCESS = 'ADMIN_UPDATE_EXPENSE_SUCCESS';
export const ADMIN_UPDATE_EXPENSE_FAILURE = 'ADMIN_UPDATE_EXPENSE_FAILURE';

export const adminUpdateExpenseRequest = () => ({
  type: ADMIN_UPDATE_EXPENSE_REQUEST
});

export const adminUpdateExpenseSuccess = (expenseId, adminApproveDate) => ({
  type: ADMIN_UPDATE_EXPENSE_SUCCESS,
  payload: { expenseId, adminApproveDate }
});

export const adminUpdateExpenseFailure = expenseId => ({
  type: ADMIN_UPDATE_EXPENSE_FAILURE,
  payload: { expenseId }
});

export const applyAdminUpdateExpense = (idToken, updateBody) => {
  return async dispatch => {
    dispatch(progressStart());
    dispatch(adminUpdateExpenseRequest());
    const client = API(idToken);
    await client
      .put('/expense', updateBody)
      .then(response => {
          dispatch(adminUpdateExpenseSuccess(updateBody.expense_id, updateBody.admin_approve_date));
          dispatch(progressComplete());
          dispatch(createSuccessSnackbar('Expense Updated Successfully'));
      })
      .catch(error => {
        dispatch(progressComplete());
        dispatch(adminUpdateExpenseFailure(updateBody.expense_id));
        dispatch(createErrorSnackbar(error.message));
      });
  };
};

// ---------------------------------------------------------------------

export const ADMIN_UNAPPROVED_EXPENSES_REQUEST = 'ADMIN_UNAPPROVED_EXPENSES_REQUEST'
export const ADMIN_UNAPPROVED_EXPENSES_SUCCESS = 'ADMIN_UNAPPROVED_EXPENSES_SUCCESS';
export const ADMIN_UNAPPROVED_EXPENSES_FAILURE = 'ADMIN_UNAPPROVED_EXPENSES_FAILURE';

export const getAdminUnapprovedExpensesRequest = resp => ({
  type: ADMIN_UNAPPROVED_EXPENSES_REQUEST
});

export const getAdminUnapprovedExpensesSuccess = resp => ({
  type: ADMIN_UNAPPROVED_EXPENSES_SUCCESS,
  payload: { resp }
});

export const getAdminUnapprovedExpensesFailure = error => ({
  type: ADMIN_UNAPPROVED_EXPENSES_FAILURE,
  payload: { error }
});

export const getAdminUnapprovedExpenses = idToken => {
  return async dispatch => {
    dispatch(progressStart());
    dispatch(getAdminUnapprovedExpensesRequest());
    const client = API(idToken);
    await client
      .get('/allExpenses?unapprovedOnly=true')
      .then(response => {
        dispatch(progressComplete());
        dispatch(getAdminUnapprovedExpensesSuccess(response));
      })
      .catch(error => {
        console.error(error);
        dispatch(progressComplete());
        dispatch(getAdminUnapprovedExpensesFailure(error));
        dispatch(createErrorSnackbar(error.message));
      });
  };
};
