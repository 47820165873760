import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import LogOutLink from '../LogOut';
import LogInLink from '../LogIn';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const Navigation = ({ idToken, employee, dispatch, history }) => {
  if (!employee && history.location.pathname === '/help') {
    return null;
  }

  return employee ? (
    <div>
      <NavbarAuth idToken={idToken} isAdmin={employee.isAdmin} dispatch={dispatch} />
    </div>
  ) : (
    <div>
      <NavbarUnAuth />
    </div>
  );
};

const NavbarAuth = ({ idToken, isAdmin, dispatch }) => {
  let adminLinks = '';
  if (isAdmin) {
    adminLinks = (
      <Fragment>
        <Link to={ROUTES.DOWNLOAD} className="nav-link" style={{ marginLeft: '5px' }}>
          Download
        </Link>
        <Link to={ROUTES.APPROVE_EXPENSE} className="nav-link" style={{ marginLeft: '5px' }}>
          Approve
        </Link>
        <Link to={ROUTES.ADD_CREDIT} className="nav-link" style={{ marginLeft: '5px' }}>
          Add Credit
        </Link>
      </Fragment>
    );
  }
  return (
    <AppBar position="static">
      <Toolbar>
        <Link to={ROUTES.EMPLOYEE} className="nav-link flex-one">
          DL Money
        </Link>

        {adminLinks}

        <Link to={ROUTES.ADD_EXPENSE} className="nav-link" style={{ marginLeft: '5px' }}>
          Add Expense
        </Link>

        <Link to={ROUTES.HELP} className="nav-link" style={{ marginLeft: '5px' }}>
          Help
        </Link>

        <LogOutLink />
      </Toolbar>
    </AppBar>
  );
};

const NavbarUnAuth = () => (
  <AppBar position="static">
    <Toolbar>
      <Typography variant="h6" color="inherit">
        DL Money
      </Typography>

      <LogInLink />
    </Toolbar>
  </AppBar>
);

export default withRouter(connect()(Navigation));
