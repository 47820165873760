import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ApproveExpenses from '../ApproveExpenses';
import UnapprovedExpenses from '../UnapprovedExpenses';
import { getAdminExpensesRequest } from '../../actions/admin.js';

class Approve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIdx: 0
    };
  }

  tabChange = (event, value) => {
    event.preventDefault();
    this.props.dispatch(getAdminExpensesRequest()); // clears expenses before tab load
    this.setState({ tabIdx: value });
  };

  render() {
    const { tabIdx } = this.state;
    return (
      <div>
        <div style={{ backgroundColor: '#aaa' }}>
          <Tabs
            value={this.state.tabIdx}
            onChange={this.tabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="By Employee" />
            <Tab label="All Unapproved" />
          </Tabs>
        </div>
        {tabIdx === 0 && <ApproveExpenses idToken={this.props.idToken}  {...this.props} />}
        {tabIdx === 1 && <UnapprovedExpenses idToken={this.props.idToken} {...this.props} />}
      </div>
    );
  }
}

export default connect()(Approve);
