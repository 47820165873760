
export const PROGRESS_START = 'PROGRESS_START';
export const PROGRESS_COMPLETE = 'PROGRESS_COMPLETE';

export const progressStart = () => ({
    type: PROGRESS_START
});

export const progressComplete = () => ({
    type: PROGRESS_COMPLETE
});

