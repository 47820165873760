import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux';
import { adminSelectEmployee } from '../../actions/admin';
import { getAdminEmployees } from '../../actions/admin';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class SelectEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getEmployeeByAuthId = this.getEmployeeByAuthId.bind(this);
  }

  componentDidMount() {
      this.props.dispatch(getAdminEmployees(this.props.idToken));
  }

  handleSelectChange = event => {
    const newSelectedEmployee = this.getEmployeeByAuthId(event.target.value);
    this.props.dispatch(adminSelectEmployee(newSelectedEmployee));
  };

  getEmployeeByAuthId = authId => {
    return this.props.employees.filter(employee => employee.auth_id === authId)[0];
  };

  render() {
    const { classes } = this.props;
    if (!this.props.employees || this.props.employees.length === 0) {
      return null;
    }

    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="employee-helper">Employee</InputLabel>
          <Select
            value={
              this.props.selectedEmployee && this.props.selectedEmployee.auth_id
                ? this.props.selectedEmployee.auth_id
                : ''
            }
            onChange={this.handleSelectChange}
            name="employee_auth_id"
            className={classes.selectEmpty}
          >
            {this.props.employees.map(n => (
              <MenuItem key={n.auth_id} value={n.auth_id}>
                {n.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

SelectEmployee.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    employees: state.adminState.employees || []
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SelectEmployee));
