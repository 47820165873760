import moment from "moment";
import { DATE_FORMAT } from "../constants/constants";

export const getDateStringParamsForSelectedMonthAndYear = (selectedYear, selectedMonth) => {
  const params = {};
  if (!Number.isInteger(selectedYear) || !Number.isInteger(selectedMonth)) {
    return null;
  }
  if (selectedMonth === 0) {
    // all months
    params.fromDate = moment(selectedYear + '-01-01').startOf('year').format(DATE_FORMAT);
    params.toDate = moment(selectedYear + '-01-01').endOf('year').format(DATE_FORMAT);
  } else {
    const month = twoDigits(selectedMonth);
    params.fromDate = moment(selectedYear + '-' + month + '-01').startOf('month').format(DATE_FORMAT);
    params.toDate = moment(selectedYear + '-' + month + '-01').endOf('month').format(DATE_FORMAT);
  }
  console.log(params);
  return params;
};

const twoDigits = val => {
  return val < 10 ? '0' + val : val + '';
};
