export const questions = [
  {
    type: 'radio',
    name: 'approved_by',
    title: 'Who approved this?',
    options: [
      { value: 'Nikki Evelyn', label: 'Nikki Evelyn' },
      { value: 'Viki Wolfe', label: 'Viki Wolfe' },
      { value: 'Will McDowell', label: 'Will McDowell' }
    ]
  },
  {
    type: 'text',
    name: 'credit_amount',
    title: 'What is the dollar amount?',
    info: 'How much do you want to add to this account?'
  },
  {
    type: 'text',
    name: 'credit_description',
    title: 'Credit Description',
    info: 'What is the reason for the credit?'
  },
  {
    type: 'admin_select',
    name: 'employee_auth_id',
    title: 'Apply Expense to Employee',
    info: 'As the admin, who are you creating this credit for?'
  }
];
