import React from 'react';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {KeyboardDatePicker} from '@material-ui/pickers';
import { connect } from 'react-redux';

import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {download} from "../../actions/download";

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});
const previousMonth = moment().subtract(1, 'months');

class DownloadPage extends React.Component {
  state = {
    startDate: previousMonth.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    endDate: previousMonth.endOf('month').format('YYYY-MM-DD HH:mm:ss')
  };

  setStartDate(date) {
    const newDate = date.startOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.setState({startDate: newDate});
  }

  setEndDate(date) {
    const newDate = date.endOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.setState({endDate: newDate});
  }

  downloadExpenses() {
    this.props.dispatch(download(this.props.idToken, this.state.startDate, this.state.endDate));
  };

  render() {
    const {classes} = this.props;

    return (
      <Container className="page">

        <div style={{marginTop: 80}}>
          <Typography variant="h6" align="center">
            DOWNLOAD EXPENSES
          </Typography>
        </div>

        <div style={{marginTop: 40}}>

          <Typography align="center">
            Select the months you wish to include in the download
          </Typography>
        </div>


        <Grid
          container
          className={classes.root} spacing={6}
          style={{marginTop: 40}}
          justify="center">

          <Grid item xs={3}>
            <KeyboardDatePicker
              views={["year", "month"]}
              name='startOfStartMonth'
              label="Start Month"
              value={this.state.startDate}
              onChange={date => this.setStartDate(date)}
            />
          </Grid>

          <Grid item xs={3}>
            <KeyboardDatePicker
              views={["year", "month"]}
              name='endOfEndMonth'
              label="End Month"
              value={this.state.endDate}
              onChange={date => this.setEndDate(date)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{marginTop: 40}}
          justify="center">

          <Button variant="outlined" onClick={() => this.downloadExpenses()}>
            Download
          </Button>
        </Grid>


      </Container>

    );
  }
}

export default connect()(withStyles(styles)(DownloadPage));


