import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CustomTableCell from '../CustomTableCell';
import UnapprovedTableRow from './UnapprovedTableRow';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Loading from '../Loading';
import UpArrow from '@material-ui/icons/ArrowDropUp';
import DownArrow from '@material-ui/icons/ArrowDropDown';
import orderBy from 'lodash/orderBy';

import { connect } from 'react-redux';
import { getAdminUnapprovedExpenses } from '../../actions/admin';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 400
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
});



const adminTableColumns = [
  { id: 'employee_name', label: 'Name', width: '60pt' },
  { id: 'reimbursement_source', label: 'Expense Type', width: '50pt' },
  { id: 'submitted_date', label: 'Submit Date', width: '50pt' },
  { id: 'approved_by', label: 'Approved By', width: '40pt' },
  { id: 'expense_business_name', label: 'Paid To', width: '60pt' },
  { id: 'expense_amount', label: 'Receipt Total', width: '50pt' },
  { id: 'is_preferred_business', label: 'Preferred Business', width: '40pt' },
  { id: 'miles_amount', label: 'Total Miles', width: '35pt' },
  { id: 'client_name', label: 'Client', width: '70pt' },
  { id: 'expense_description', label: 'Description', width: '160pt' },
  { id: 'receipt_url', label: 'Receipt', width: '30pt' },
  { id: 'admin_approve_date', label: 'Admin Approved', width: '60pt' }
];

const toggleDirection = {
  asc: 'desc',
  desc: 'asc'
};

class UnapprovedExpensesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnToSort: 'submitted_date',
      sortDirection: 'desc'
    };
  }

  componentDidMount() {
    this.props.dispatch(getAdminUnapprovedExpenses(this.props.idToken));
  }

  handleSort = columnId => event => {
    if (columnId !== 'admin_approve_date') {
      this.setState(state => ({
        columnToSort: columnId,
        sortDirection: state.columnToSort === columnId ? toggleDirection[state.sortDirection] : 'asc'
      }));
    }
  };

  render() {
    if (this.props.loading)  {
      return (
        <div>
          <Loading loadMessage="Loading Unapproved Expenses.." />
        </div>
      );
    }

    const { classes, expenses } = this.props;
    return (
      <div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {adminTableColumns.map(
                  column => (
                    <CustomTableCell
                      key={column.id}
                      align="left"
                      onClick={this.handleSort(column.id)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', width: column.width }}>
                        <span>{column.label}</span>
                        {this.state.columnToSort === column.id ? (
                          this.state.sortDirection === 'asc' ? (
                            <UpArrow />
                          ) : (
                            <DownArrow />
                          )
                        ) : null}
                      </div>
                    </CustomTableCell>
                  ),
                  this
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {orderBy(expenses, this.state.columnToSort, this.state.sortDirection).map(n => (
                <UnapprovedTableRow key={n.expense_id}
                idToken={this.props.idToken} expense={n}/>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    expenses: state.adminState.expenses,
    loading: state.loadingState.loading,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(UnapprovedExpensesTable));
