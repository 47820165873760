import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Currency from '../Currency';
import SelectYear from '../SelectYear';
import SelectMonth from '../SelectMonth';
import { connect } from 'react-redux';

class EmployeeHeader extends Component {
  render() {
    return (
      <Grid container>
        <Grid item sm style={{ background: '#fff', paddingLeft: '10px' }}>
          <Typography variant="h5" color="secondary">
            Welcome back, {this.props.employee.name}!
          </Typography>
          <Typography variant="h6" align="left" color="secondary" paragraph>
            Current Coin-Craft Balance: <Currency dollars={this.props.employee.current_balance} />
          </Typography>

          <div style={{ display: 'inline-flex', width: '100%' }}>
            <SelectMonth selectedMonth={this.props.selectedMonth} admin={false} />
            <SelectYear selectedYear={this.props.selectedYear} admin={false} />
          </div>
        </Grid>

        <Grid item sm style={{ background: '#fff', textAlign: 'right', paddingRight: '10px' }}>
          <EmployeeHeaderInfoBlock employee={this.props.employee} expenses={this.props.expenses} />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    employee: state.employeeState.employee,
    expenses: state.expensesState.expenses,
    selectedYear: state.expensesState.selectedYear,
    selectedMonth: state.expensesState.selectedMonth
  };
};

export default connect(mapStateToProps)(EmployeeHeader);

const EmployeeHeaderInfoBlock = ({ employee, expenses }) => {
  const currentYear = new Date().getFullYear();
  let taxableIncomeYtd = 0;
  let companyBenefitYtd = 0;
  let regularExpensesYtd = 0;
  let creditYtd = 0;

  expenses.forEach(expense => {
    const expenseYear = expense.reimbursement_type === 'CREDIT' ?
        expense.submitted_date : expense.receipt_date;

    if (expenseYear.startsWith(currentYear)) {
      switch (expense.reimbursement_source) {
        case 'LABS': {
          regularExpensesYtd += Number(expense.expense_amount);
          break;
        }

        case 'COIN_CRAFT_TAXABLE': {
          taxableIncomeYtd += Number(expense.expense_amount);
          break;
        }

        case 'COIN_CRAFT_UNTAXABLE': {
          companyBenefitYtd += Number(expense.expense_amount);
          break;
        }

        case 'CREDIT': {
          creditYtd += Number(expense.expense_amount);
          break;
        }

        default:
      }
    }

  });

  return (
    <div>
      <Typography variant="h6" style={{ marginBottom: '4px' }} color="secondary">
        Year-To-Date
      </Typography>
      <Typography variant="body1" color="secondary">
        C+C Taxable Income: <Currency dollars={taxableIncomeYtd} />
      </Typography>

      <Typography variant="body1" color="secondary">
        C+C Company Benefit: <Currency dollars={companyBenefitYtd} />
      </Typography>

      <Typography variant="body1" color="secondary">
        Regular Expenses: <Currency dollars={regularExpensesYtd} />
      </Typography>

      <Typography variant="body1" color="secondary">
        Credits: <Currency dollars={-creditYtd} />
      </Typography>
    </div>
  );
};
