import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import FirebaseInstance from '../Firebase';
import Navigation from '../Navigation';
import Notifier from '../Notifier';
import Loading from '../Loading';
import Routes from '../Routes';
import { getLogoutSuccess } from '../../actions/logout';
import { createErrorSnackbar } from '../../actions/snackbar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { idToken: null };
  }

  componentDidMount() {
    this.listener = FirebaseInstance.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        FirebaseInstance.auth.currentUser
          .getIdToken(true)
          .then(idToken => {
            this.setState({ idToken: idToken });
          })
          .catch(error => {
            console.error(error);
            this.props.dispatch(createErrorSnackbar('Could not retrieve token'));
          });
      } else {
        this.setState({ idToken: null, employee: {} }, () => this.props.dispatch(getLogoutSuccess()));
      }

      // We force any new screen loads back to home page, *unless* its the help screen
      // (the mobile apps need to be able to load the help screen).
      if (this.props.history.location.pathname !== '/help') {
        this.props.history.push('/');
      }
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    return (
      <Fragment>
        <Notifier />
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <>
              <Navigation idToken={this.state.idToken} employee={this.props.employee} />
              {this.props.showProgress > 0 && <LinearProgress />}
              {this.props.employeeError ? (
                <Loading loadMessage="Employee not found in database. Please contact the Money app team." />
              ) : (
                <Routes idToken={this.state.idToken} />
              )}
            </>
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    employee: state.employeeState.employee,
    employeeError: state.employeeState.error,
    showProgress: state.progressState.progressCount
  };
};

export default withRouter(connect(mapStateToProps)(App));
