export const THEME = {
  palette: {

    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#555'
    }
  },
  typography: {
    useNextVariants: true
  }
};
