import React, { Component } from 'react';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RadioButtonGroup from '../forms/RadioButtonGroup';
import DateSelector from '../forms/DateSelector';
import SelectEmployee from '../SelectEmployee';
import TextEntry from '../forms/TextEntry';
import { questions } from './creditFormQuestions';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { postExpense } from '../../actions/expenses';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: `${theme.spacing(1)}px 0`
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25px',
    background: 'blue'
  }
});

const validationSchema = Yup.object({
  approved_by: Yup.string().required('Required'),
  credit_amount: Yup.string()
    .required('Required')
    .matches(/^[0-9]{1,5}(\.[0-9]{2})?$/, { message: 'Must be positive integer or 2-place decimal. Max of 99999.99' }),
  credit_description: Yup.string().required('Required')
});

class AddCreditForm extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.selectedEmployee !== prevProps.selectedEmployee) {
      this.props.setFieldValue('employee_auth_id', this.props.selectedEmployee.auth_id);
    }
  }

  render() {
    const { values, isSubmitting, handleChange } = this.props;
    if (this.props.signedInEmployee === null) {
        return null;
    }

    return (
      <div style={{ margin: '25px' }}>
        <Form>
          {questions.map(question => {
            switch (question.type) {
              case 'radio':
                return (
                  <RadioButtonGroup
                    key={question.name}
                    question={question}
                    values={values}
                    handleChange={handleChange}
                  />
                );

              case 'text':
                return (
                  <TextEntry key={question.name} question={question} values={values} handleChange={handleChange} />
                );

              case 'date':
                return <DateSelector key={question.name} name={question.name} question={question} />;

              case 'admin_select':
                if (this.props.signedInEmployee.isAdmin) {
                  return (
                    <div key={question.name} style={{ margin: '0px 0px 30px 5px' }}>
                      {question.title}
                      <div style={{ color: 'grey', fontSize: 'smaller', margin: '5px' }}>{question.info}</div>
                      <SelectEmployee
                        key={question.name}
                        idToken={this.props.idToken}
                        selectedEmployee={this.props.selectedEmployee}
                      />
                    </div>
                  );
                } else {
                  return null;
                }

              default:
                return 'default';
            }
          })}

          <Button type="submit" variant="outlined" color="secondary" disabled={isSubmitting}>
            {isSubmitting ? 'WAIT..' : 'SUBMIT'}
          </Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    signedInEmployee: state.employeeState.employee,
    selectedEmployee: state.adminState.selectedEmployee || state.employeeState.employee
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withStyles(styles),
  withFormik({
    mapPropsToValues({
        selectedEmployee,
        employee_auth_id,
        approved_by,
        credit_amount,
        credit_description
    }) {
      return {
        employee_auth_id: employee_auth_id || selectedEmployee.auth_id || '',
        approved_by: approved_by || '',
        credit_amount: credit_amount || '',
        credit_description: credit_description || ''
      };
    },
    validationSchema: validationSchema,
    handleSubmit: (values, { props, setSubmitting }) => {
      const idToken = props.idToken;
      const expenseBody = prepareCreditExpense(values);
      props.dispatch(postExpense(idToken, expenseBody, props.history));
    }
  })
)(AddCreditForm);

// A credit is just a special kind of expense.
// Note we are negating the credit amount below.
const prepareCreditExpense = data => {
  const expense = {};
  expense.employee_auth_id = data.employee_auth_id;
  expense.approved_by = data.approved_by;
  expense.expense_amount = -data.credit_amount;
  expense.expense_description = data.credit_description;
  expense.reimbursement_source = 'CREDIT';
  expense.reimbursement_type = 'CREDIT';

  if (expense.reimbursement_source === 'CREDIT') {
    expense.admin_approve_date = new Date()
  }
  return expense;
};
