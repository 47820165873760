import FirebaseInstance from '../components/Firebase';
import { createErrorSnackbar } from './snackbar';
import { progressStart, progressComplete } from './progress';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const getLogoutRequest = () => ({
  type: LOGOUT_REQUEST
});

export const getLogoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

export const getLogoutFailure = error => ({
  type: LOGOUT_FAILURE,
  payload: { error }
});

export const getLogout = () => {
  return async dispatch => {
    dispatch(progressStart());
    dispatch(getLogoutRequest());
    await FirebaseInstance.doLogOut()
      .then(authUser => {
        dispatch(progressComplete());
      })
      .catch(error => {
        dispatch(progressComplete());
        dispatch(getLogoutFailure(error));
        dispatch(createErrorSnackbar('Logout Failed'));
      });
  };
};
