import API from '../components/API';
import { createErrorSnackbar, createSuccessSnackbar } from './snackbar';
import { progressStart, progressComplete } from './progress';

export const GET_EXPENSES_REQUEST = 'GET_EXPENSES_REQUEST';
export const GET_EXPENSES_SUCCESS = 'GET_EXPENSES_SUCCESS';
export const GET_EXPENSES_FAILURE = 'GET_EXPENSES_FAILURE';

export const getExpensesRequest = () => ({
  type: GET_EXPENSES_REQUEST
});

export const getExpensesSuccess = resp => ({
  type: GET_EXPENSES_SUCCESS,
  payload: { resp }
});

export const getExpensesFailure = error => ({
  type: GET_EXPENSES_FAILURE,
  payload: { error }
});

export const getExpenses = idToken => {
  return async dispatch => {
    dispatch(progressStart());
    dispatch(getExpensesRequest());
    const client = API(idToken);
    await client
      .get('/expenses')
      .then(response => {
          dispatch(getExpensesSuccess(response));
          dispatch(progressComplete());
      })
      .catch(error => {
        dispatch(progressComplete());
        dispatch(getExpensesFailure(error));
        dispatch(createErrorSnackbar(error.message));
      });
  };
};

// ---------------------------------------------------------------------

export const POST_EXPENSE_REQUEST = 'POST_EXPENSE_REQUEST';
export const POST_EXPENSE_SUCCESS = 'POST_EXPENSE_SUCCESS';
export const POST_EXPENSE_FAILURE = 'POST_EXPENSE_FAILURE';

export const postExpenseRequest = () => ({
  type: POST_EXPENSE_REQUEST
});

export const postExpenseSuccess = resp => ({
  type: POST_EXPENSE_SUCCESS,
  payload: { resp }
});

export const postExpenseFailure = error => ({
  type: POST_EXPENSE_FAILURE,
  payload: { error }
});

export const postExpense = (idToken, expenseBody, history) => {
  return dispatch => {
    dispatch(progressStart());
    dispatch(postExpenseRequest());
    const client = API(idToken);
    client
      .post('/expense', expenseBody)
      .then(response => {
        dispatch(progressComplete());
        dispatch(postExpenseSuccess(response));
        dispatch(createSuccessSnackbar('Operation Succeeded!'));
        history.goBack();
      })
      .catch(error => {
        console.error(error);
        dispatch(progressComplete());
        dispatch(postExpenseFailure(error));
        dispatch(createErrorSnackbar(error.message));
      });
  };
};

// ---------------------------------------------------------------------

export const EMPLOYEE_SELECT_YEAR = 'EMPLOYEE_SELECT_YEAR';
export const EMPLOYEE_SELECT_MONTH = 'EMPLOYEE_SELECT_MONTH';

export const employeeSelectYear = year => ({
  type: EMPLOYEE_SELECT_YEAR,
  payload: year
});

export const employeeSelectMonth = month => ({
  type: EMPLOYEE_SELECT_MONTH,
  payload: month
});
