import { combineReducers } from 'redux';
import adminReducer from './admin';
import progressReducer from './progress';
import loginReducer from './login';
import loadingReducer from './loading';
import logoutReducer from './logout';
import employeeReducer from './employee';
import expensesReducer from './expenses';
import snackbarReducer from './snackbar';

const rootReducer = combineReducers({
  adminState: adminReducer,
  loginState: loginReducer,
  logoutState: logoutReducer,
  loadingState: loadingReducer,
  employeeState: employeeReducer,
  expensesState: expensesReducer,
  progressState: progressReducer,
  app: snackbarReducer,
});

export default rootReducer;
