import React, { Component } from 'react';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import RadioButtonGroup from '../forms/RadioButtonGroup';
import DateSelector from '../forms/DateSelector';
import TextEntry from '../forms/TextEntry';
import SelectEmployee from '../SelectEmployee';
import { questions } from './expenseFormQuestions';
import * as Yup from 'yup';
import FileUpload from '../FileUpload';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { postExpense } from '../../actions/expenses';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(3)
  },
  group: {
    margin: `${theme.spacing(1)}px 0`
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25px',
    background: 'blue'
  }
});

const validationSchema = Yup.object({
  reimbursement_type: Yup.string().required('Required'),
  is_coin_craft: Yup.string().required('Required'),
  approved_by: Yup.string().required('Required'),
  expense_description: Yup.string().required('Required'),
  receipt_date: Yup.string().required('Required').nullable(),
  reimbursement_source: Yup.string().when('is_coin_craft', (is_coin_craft, schema) => {
    if (is_coin_craft === 'true') {
      return schema.required('Required');
    }
    return schema;
  }),
  expense_amount: Yup.string().when('reimbursement_type', (reimbursement_type, schema) => {
    if (reimbursement_type === 'RECEIPT') {
      return schema.required('Required')
      .matches(/^[0-9]{1,5}(\.[0-9]{2})?$/, { message: 'Must be positive integer or 2-place decimal. Max of   99999.99' })
    }
    return schema;
  }),
  mileage_amount: Yup.string().when('reimbursement_type', (reimbursement_type, schema) => {
    if (reimbursement_type === 'MILEAGE') {
      return schema.required('Required')
      .matches(/^[0-9]{1,5}$/, { message: 'Must be positive integer. Max of 99999' })
    }
    return schema;
  }),
  expense_business_name: Yup.string().when('reimbursement_type', (reimbursement_type, schema) => {
    if (reimbursement_type === 'RECEIPT') {
      return schema.required("Required when Question 1 is 'Expense'");
    }
    return schema;
  }),
  is_preferred_business:  Yup.string().when('reimbursement_type', (reimbursement_type, schema) => {
      if (reimbursement_type === 'RECEIPT') {
      return schema.required("Required when Question 1 is 'Expense'");
    }
    return schema;
  })
});
class AddExpenseForm extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.selectedEmployee !== prevProps.selectedEmployee) {
      this.props.setFieldValue('employee_auth_id', this.props.selectedEmployee.auth_id);
    }
  }

  render() {
    if (this.props.signedInEmployee === null) {
      return null;
    }

    const { values, isSubmitting, handleChange, setFieldValue } = this.props;
    return (
      <div style={{ margin: '25px' }}>
        <Form>
          <Grid container>
            <Grid item sm style={{ background: '#fff' }}>
              {questions.map(question => {
                switch (question.type) {
                  case 'radio':
                    if (question.name === 'reimbursement_source' &&
                        values.is_coin_craft === 'false') {
                        values.reimbursement_source = '';
                        return null;
                    }

                    if (question.name === 'is_preferred_business' &&
                      values.reimbursement_type &&
                      values.reimbursement_type !== 'RECEIPT') {
                      values.is_preferred_business = '';
                      return null;
                    }

                    return (
                      <RadioButtonGroup
                        key={question.name}
                        question={question}
                        values={values}
                        handleChange={handleChange}
                      />
                    );

                  case 'text':
                    if (question.name === 'expense_amount' &&
                        values.reimbursement_type &&
                        values.reimbursement_type !== 'RECEIPT') {
                        return null;
                    }

                    if (question.name === 'mileage_amount' && values.reimbursement_type !== 'MILEAGE') {
                        return null;
                    }

                    return (
                      <TextEntry key={question.name} question={question} values={values} handleChange={handleChange} />
                    );

                  case 'date':
                    return <DateSelector key={question.name} name={question.name} question={question} />;

                  case 'admin_select':
                    if (this.props.signedInEmployee.isAdmin) {
                      return (
                        <div key={question.name} style={{ margin: '0px 0px 30px 5px' }}>
                          {question.title}
                          <div style={{ color: 'grey', fontSize: 'smaller', margin: '5px' }}>{question.info}</div>
                          <SelectEmployee
                            key={question.name}
                            idToken={this.props.idToken}
                            selectedEmployee={this.props.selectedEmployee}
                          />
                        </div>
                      );
                    } else {
                      return null;
                    }

                  default:
                    return 'default';
                }
              })}
            </Grid>

            <Grid item sm style={{ background: '#fff' }}>
              {values.reimbursement_type !== 'MILEAGE' && (
                <FileUpload
                  employee={this.props.selectedEmployee}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
            </Grid>
          </Grid>

          <div  style={{ margin: '0px 0px 30px 5px' }}>
           Before clicking SUBMIT: If you have a photo of your receipt to include, make sure you upload it first.
          </div>
          <Button type="submit" variant="outlined" color="secondary" disabled={isSubmitting}>
            {isSubmitting ? 'WAIT..' : 'SUBMIT'}
          </Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedEmployee: state.adminState.selectedEmployee || state.employeeState.employee,
    signedInEmployee: state.employeeState.employee
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withStyles(styles),
  withFormik({
    mapPropsToValues({
      selectedEmployee,
      employee_auth_id,
      reimbursement_type,
      is_coin_craft,
      reimbursement_source,
      approved_by,
      expense_amount,
      mileage_amount,
      receipt_date,
      client_name,
      expense_business_name,
      expense_description,
      receipt_url,
      is_preferred_business
    }) {
      return {
        employee_auth_id: employee_auth_id || selectedEmployee.auth_id || '',
        reimbursement_type: reimbursement_type || '',
        is_coin_craft: is_coin_craft || '',
        reimbursement_source: reimbursement_source || '',
        approved_by: approved_by || '',
        expense_amount: expense_amount || '',
        mileage_amount: mileage_amount || '',
        receipt_date: receipt_date || '',
        client_name: client_name || '',
        expense_business_name: expense_business_name || '',
        expense_description: expense_description || '',
        receipt_url: receipt_url || '',
        is_preferred_business: is_preferred_business || ''
      };
    },

    validationSchema: validationSchema,

    handleSubmit: (values, { props, setSubmitting }) => {
      const idToken = props.idToken;
      const expenseBody = prepareExpense(values);
      props.dispatch(postExpense(idToken, expenseBody, props.history));
    }
  })
)(AddExpenseForm);

const prepareExpense = data => {
  const expense = {};
  expense.employee_auth_id = data.employee_auth_id;
  expense.approved_by = data.approved_by;
  expense.client_name = data.client_name;
  expense.expense_description = data.expense_description;
  expense.reimbursement_type = data.reimbursement_type;
  expense.reimbursement_source = data.reimbursement_source || 'LABS';
  expense.receipt_date = new Date(data.receipt_date).toISOString();
  if (data.reimbursement_type === 'RECEIPT') {
    expense.expense_amount = data.expense_amount;
    expense.miles_amount = '0';
    expense.expense_business_name = data.expense_business_name;
    expense.receipt_url = data.receipt_url;
    expense.receipt_type = 'UPLOAD';
    expense.is_preferred_business = data.is_preferred_business === 'true';
} else {
    expense.expense_amount = '0';
    expense.miles_amount = data.mileage_amount;
}
  return expense;
};
