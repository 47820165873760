import React from 'react';
import Typography from '@material-ui/core/Typography';

const Landing = props => {
  return (
    <div style={{marginTop: 80}}>
        <Typography variant="h6" align="center">
          Click the <b>Login</b> button to begin..
        </Typography>
    </div>
  );
};

export default Landing;
