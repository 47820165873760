import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';

const DateSelector = props => {
  const { question } = props;
  return (
    <div key={question.name} style={{ margin: '0px 0px 40px 5px' }}>
      {question.title}
      <div style={{ color: 'grey', fontSize: 'smaller', margin: '5px' }}>{question.info}</div>
      <Grid item container justify="flex-start" xs={12}>
        <div className="picker">
          <Field id={question.name} name={question.name} component={DatePickerField} />
        </div>
      </Grid>
      <ErrorMessage name={question.name}>{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
    </div>
  );
};

export default DateSelector;

const renderLabel = date => {
  if (date !== null && date !== '' && date.isValid()) {
    return date.format('L');
  } else {
    return '';
  }
};

const DatePickerField = ({ field, form, ...other }) => {
    return (
    <KeyboardDatePicker
      InputProps={{
        inputProps: {
          readOnly: true,
        }
      }}
      clearable
      name={field.name}
      value={field.value || null}
      format={"MM/DD/YYYY"}
      labelFunc={renderLabel}
      disableFuture
      error={false}
      onChange={date => form.setFieldValue(field.name, date, true)}
      {...other}
    />
  );
};
