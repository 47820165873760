import React from 'react';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import { applyAdminUpdateExpense } from '../../actions/admin';

class ApproveSwitch extends React.Component {
  state = {
    checked: this.props.adminApproveDate.length > 0
  };

  static defaultProps = {
    error: null
  };

  handleSwitchToggle = name => event => {
    this.setState({ checked: event.target.checked }, () => this.updateExpense());
  };

  updateExpense = () => {
    const approveDate =
      this.props.adminApproveDate && this.props.adminApproveDate.length > 0 ? '' : new Date().toISOString();
    const updateBody = { expense_id: this.props.expenseId, admin_approve_date: approveDate };
    this.props.dispatch(applyAdminUpdateExpense(this.props.idToken, updateBody));
  };

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error && this.props.error === this.props.expenseId) {
      this.setState({ checked: !this.state.checked });
    }
  }

  render() {
    return (
      <div>
        <Switch
          value={this.props.adminApproveDate || ''}
          checked={this.state.checked}
          onChange={this.handleSwitchToggle()}
          disabled={this.props.disable}
          color="primary"
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.adminState.updateExpenseError
  };
};

export default connect(mapStateToProps)(ApproveSwitch);
