import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class UnapprovedExpensesHeader extends Component {
  render() {
    return (
      <div>
        <div>
          <Grid container>
            <Grid item xs>
            <Typography style={{ margin: '16px' }} variant="h5" color="textSecondary">
              Currently Unapproved Expenses
            </Typography>
            </Grid>
            <Grid item xs>

            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}


export default UnapprovedExpensesHeader;
