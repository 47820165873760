import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './components/App';
import { THEME } from './theme';
import './styles.css';
import store from './store';

const theme = createMuiTheme(THEME);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Router>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </Router>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);
