export const questions = [
  {
    type: 'radio',
    name: 'reimbursement_type',
    title: 'What are you submitting?',
    options: [{ value: 'RECEIPT', label: 'Expense' }, { value: 'MILEAGE', label: 'Mileage' }]
  },
  {
    type: 'radio',
    name: 'is_coin_craft',
    title: 'Will this be deducted from your C+C account?',
    options: [{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]
  },
  {
    type: 'radio',
    name: 'reimbursement_source',
    title: 'What type of C+C are you submitting?',
    info:
      "Ask Will McDowell if you aren't sure. Taxable Income will not be on the check, it will be through payroll with taxes deducted. Hardware/Software specific entries that are Company Benefit will be owned by Detroit Labs. ",
    options: [
      { value: 'COIN_CRAFT_UNTAXABLE', label: 'Company Benefit' },
      { value: 'COIN_CRAFT_TAXABLE', label: 'Taxable Income' }
    ]
  },
  {
    type: 'radio',
    name: 'approved_by',
    title: 'Who approved this?',
    options: [
      { value: 'Nikki Evelyn', label: 'Nikki Evelyn' },
      { value: 'Viki Wolfe', label: 'Viki Wolfe' },
      { value: 'Will McDowell', label: 'Will McDowell' }
    ]
  },
  {
    type: 'text',
    name: 'expense_amount',
    title: 'What is the dollar amount owed on the receipt?'
  },
  {
    type: 'text',
    name: 'mileage_amount',
    title: 'What are the reimbursable miles traveled?',
    info: 'Note: This is NOT just miles of your trip. This is the total miles you traveled minus the amount of miles spent on your normal commute to work. IRS Rules :/. Example: If you live 15 miles from work, and went to a client site instead of the office, subtract 30 miles from the trip.'
  },
  {
    type: 'date',
    name: 'receipt_date',
    title: 'What is the date on the receipt (for expense) or Date of Travel (for mileage)?',
  },
  {
    type: 'text',
    name: 'client_name',
    title: 'If this is specific to a client project, what is the client?',
    info: 'Travel Expenses, Retros, Licenses Acquired, etc.'
  },
  {
    type: 'text',
    name: 'expense_business_name',
    title: 'Business Name',
    info: 'What business received this money?'
  },
  {
    type: 'radio',
    name: 'is_preferred_business',
    title: 'Was this purchase made from a local and/or minority owned business?',
    options: [{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]
  },
  {
    type: 'text',
    name: 'expense_description',
    title: 'Expense Description',
    info:
      'Must answer What, Where, Why, and Who else was involved. Please do not make the answer longer than one sentence if possible.'
  },
  {
    type: 'admin_select',
    name: 'employee_auth_id',
    title: 'Apply Expense to Employee',
    info:
      'As the admin, who are you creating this expense for?'
  },
];
