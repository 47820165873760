import { ERROR_SNACKBAR, SUCCESS_SNACKBAR } from '../actions/snackbar';

// https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example
const INITIAL_STATE = {
  notifications: []
};

export default function snackbarReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  const matches = /(.*)_(SNACKBAR)/.exec(type);

  if (!matches) return state;

  if (action.type === 'REMOVE_SNACKBAR') {
    return {
      ...state,
      notifications: state.notifications.filter(notification => notification.key !== action.key)
    };
  } else {
    let key;
    switch (action.type) {
      case ERROR_SNACKBAR:
        key = new Date().getTime() + Math.random();
        const errorNotification = { key, ...payload };
        return {
          ...state,
          notifications: [...state.notifications, errorNotification]
        };

      case SUCCESS_SNACKBAR:
        key = new Date().getTime() + Math.random();
        const successNotification = { key, ...payload };
        return {
          ...state,
          notifications: [...state.notifications, successNotification]
        };

      default:
        return state;
    }
  }
}
