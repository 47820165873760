import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Currency from '../Currency';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: 0,
    margin: 0
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="body1">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: '0px 0px -4px 0px',
    padding: 0,
    maxWidth: '90vw'
  }
}))(MuiDialogContent);

class ReceiptDialog extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        {this.props.receiptUrl.includes('.pdf') ? 
        (<Button variant="outlined" color="secondary" target="_blank" href={this.props.receiptUrl}>VIEW</Button>) :
        (<Button variant="outlined" color="secondary" onClick={this.handleClickOpen}>VIEW</Button>)}
        <Dialog
          maxWidth={'md'}
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Receipt Date: {new Date(this.props.receipt_date).toLocaleDateString('en-US')}
            &nbsp;&nbsp;&nbsp; Amount: <Currency dollars={this.props.amount} />
          </DialogTitle>
          <DialogContent>
            <img src={this.props.receiptUrl} alt="receipt" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default ReceiptDialog;
