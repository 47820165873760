import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import AddExpenseForm from './AddExpenseForm';

class AddExpense extends Component {
  render() {
    return (
      <div style={{ margin: '20px' }}>
        <Typography variant="h6" align="center">
          Submit an Expense
        </Typography>
        <AddExpenseForm idToken={this.props.idToken} key="AddExpenseForm" />
      </div>
    );
  }
}

export default AddExpense;
