import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#555',
    color: theme.palette.common.white,
    fontSize: 12,
    padding: '8px 8px 8px 16px',
    lineHeight: '1rem'

  },
  body: {
    padding: '8px 8px 8px 16px',
    fontSize: 12,
    
  }
}))(TableCell);

export default CustomTableCell;
