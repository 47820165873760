import React, { Component } from 'react';
import UnapprovedExpensesHeader from './UnapprovedExpensesHeader';
import UnapprovedExpensesTable from './UnapprovedExpensesTable';

class UnapprovedExpenses extends Component {
  render() {
    return (
      <div className="page">
        <UnapprovedExpensesHeader idToken={this.props.idToken} />
        <UnapprovedExpensesTable idToken={this.props.idToken} />
      </div>
    );
  }
}

export default UnapprovedExpenses;
