import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux';
import { adminSelectMonth } from '../../actions/admin';
import { employeeSelectMonth } from '../../actions/expenses';
import { months } from '../../constants/constants';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class SelectMonth extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    const selectedMonth = event.target.value;
    if (this.props.admin) {
      this.props.dispatch(adminSelectMonth(selectedMonth));
    } else {
      this.props.dispatch(employeeSelectMonth(selectedMonth));
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <InputLabel>Month</InputLabel>
            <Select
              value={this.props.selectedMonth}
              onChange={this.handleChange}
              name="selectedMonth"
              className={classes.selectEmpty}
            >
              {months.map((month, idx) => {
                return (
                  <MenuItem key={idx} value={idx}>
                    {month}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </form>
      </div>
    );
  }
}

SelectMonth.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect()(withStyles(styles)(SelectMonth));
