import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { getLogin } from '../../actions/login';

class LogInLink extends Component {
  logIn = event => {
    event.preventDefault();
    this.props.dispatch(getLogin());
  };

  render() {
    return (
      <Button
        color = 'inherit' onClick={this.logIn}
        style={{ marginLeft: 'auto' }}
      >
        Log In
      </Button>
    );
  }
}

export default connect()(LogInLink);
