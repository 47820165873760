import {
  ADMIN_GET_EXPENSES_REQUEST,
  ADMIN_GET_EXPENSES_SUCCESS,
  ADMIN_GET_EXPENSES_FAILURE,
  ADMIN_GET_EMPLOYEES_REQUEST,
  ADMIN_GET_EMPLOYEES_SUCCESS,
  ADMIN_GET_EMPLOYEES_FAILURE,
  ADMIN_SELECT_YEAR,
  ADMIN_SELECT_MONTH,
  ADMIN_SELECT_EMPLOYEE,
  ADMIN_UPDATE_EXPENSE_REQUEST,
  ADMIN_UPDATE_EXPENSE_SUCCESS,
  ADMIN_UPDATE_EXPENSE_FAILURE,
  ADMIN_UNAPPROVED_EXPENSES_REQUEST,
  ADMIN_UNAPPROVED_EXPENSES_SUCCESS,
  ADMIN_UNAPPROVED_EXPENSES_FAILURE,
} from '../actions/admin';

import { POST_EXPENSE_SUCCESS } from '../actions/expenses';
import { LOGOUT_SUCCESS } from '../actions/logout';

const today = new Date();

const INITIAL_STATE = {
  selectedYear: today.getFullYear(),
  selectedMonth: today.getMonth() + 1,
  selectedEmployee: {},
  expenses: [],
  employees: [],
  updateExpenseError: null
};

export default function adminReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADMIN_GET_EXPENSES_REQUEST:
      return {
        ...state,
        expenses: [],
        error: null
      };

    case ADMIN_GET_EXPENSES_SUCCESS:
      return {
        ...state,
        expenses: action.payload.resp.data
      };

    case ADMIN_GET_EXPENSES_FAILURE:
      return {
        ...state,
        expenses: []
      };

    // -------------------------------------------------

    case ADMIN_GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        error: null
      };

      case ADMIN_GET_EMPLOYEES_SUCCESS:
      // Update employees array, but also selectedEmployee, since it may
      // have a new, updated current balance.
        const employeesResults = action.payload.resp.data
        const updatedSelectedEmployee = employeesResults.find(employee => employee.auth_id === state.selectedEmployee.auth_id);
        return {
          ...state,
          selectedEmployee: updatedSelectedEmployee ? updatedSelectedEmployee : state.selectedEmployee,
          employees: employeesResults
        };

    case ADMIN_GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        employees: [],
        error: action.payload.error
      };

    // -------------------------------------------------

    case LOGOUT_SUCCESS:
      return {
        ...state,
        expenses: [],
        employees: []
      };

    // -------------------------------------------------

    case ADMIN_SELECT_YEAR:
      return {
        ...state,
        selectedYear: action.payload
      };

    case ADMIN_SELECT_MONTH:
      return {
        ...state,
        selectedMonth: action.payload
      };

    case ADMIN_SELECT_EMPLOYEE:
      return {
        ...state,
        selectedEmployee: action.payload
      };

    // -------------------------------------------------

    case POST_EXPENSE_SUCCESS:
      return {
        ...state,
        expenses: [],
        employees: []
      };

    // -------------------------------------------------

    case ADMIN_UPDATE_EXPENSE_REQUEST:
      return {
        ...state,
        updateExpenseError: null
      };

    case ADMIN_UPDATE_EXPENSE_SUCCESS:
      // updating expense with new admin_approve_date status
      const newExpenseArray = state.expenses.map(item => {
        if (item.expense_id === action.payload.expenseId) {
          return {
            ...item,
            admin_approve_date: action.payload.adminApproveDate
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        expenses: newExpenseArray
      };

    case ADMIN_UPDATE_EXPENSE_FAILURE:
      return {
        ...state,
        updateExpenseError: action.payload.expenseId
      };

    // -------------------------------------------------

    case ADMIN_UNAPPROVED_EXPENSES_REQUEST:
      return {
        ...state,
        expenses: [],
      };

    // -------------------------------------------------

    case ADMIN_UNAPPROVED_EXPENSES_SUCCESS:
      return {
        ...state,
        expenses: action.payload.resp.data
      };

    case ADMIN_UNAPPROVED_EXPENSES_FAILURE:
      return {
        ...state,
        expenses: [],
      };

    default:
      return state;
  }
}
