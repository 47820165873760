import React, { Component } from 'react';
import { connect } from 'react-redux';

import Check from '@material-ui/icons/Check';
import CustomTableCell from '../CustomTableCell';
import Currency from '../Currency';
import ReceiptDialog from '../ReceiptDialog';
import ApproveSwitch from '../ApproveSwitch';

import TableRow from '@material-ui/core/TableRow';

const reimbursement_source = type => {
  if (type === 'COIN_CRAFT_UNTAXABLE') {
    return 'Company Benefit';
  } else if (type === 'COIN_CRAFT_TAXABLE') {
    return 'Taxable Income';
  } else if (type === 'CREDIT') {
    return 'Credit';
  } else {
    return 'Not Coin+Craft';
  }
};

class UnapprovedExpensesTableRow extends Component {

  render() {
    const { expense } = this.props;
    if (expense.reimbursement_source === 'CREDIT') {
        return null;
    }

    return (
      <TableRow key={expense.expense_id}>
        <CustomTableCell scope="row">{expense.employee_name}</CustomTableCell>
        <CustomTableCell>{reimbursement_source(expense.reimbursement_source)}</CustomTableCell>
        <CustomTableCell align="left">{new Date(expense.submitted_date).toLocaleDateString('en-US')}</CustomTableCell>
        <CustomTableCell align="left">{expense.approved_by}</CustomTableCell>
        <CustomTableCell align="left" style={{ overflowWrap: 'break-word' }}>
          {expense.expense_business_name ? expense.expense_business_name : 'N/A'}
        </CustomTableCell>
        <CustomTableCell align="left">
          <Currency dollars={expense.expense_amount} />
          <br />
          <div style={{ color: 'grey', marginTop: 5 }}>
            {expense.receipt_date ? new Date(expense.receipt_date).toLocaleDateString('en-US') : 'N/A'}
          </div>
        </CustomTableCell>
        <CustomTableCell align="center" style={{ overflowWrap: 'break-word' }}>
          {expense.is_preferred_business ? <Check/> : ''}
        </CustomTableCell>
        <CustomTableCell align="left">{expense.miles_amount}</CustomTableCell>
        <CustomTableCell align="left">{expense.client_name ? expense.client_name : 'N/A'}</CustomTableCell>
        <CustomTableCell align="left" style={{ overflowWrap: 'break-word' }}>
          {expense.expense_description}
        </CustomTableCell>
        <CustomTableCell align="left">
          {expense.receipt_url && (
            <ReceiptDialog
              receipt_date={expense.receipt_date}
              amount={expense.expense_amount}
              receiptUrl={expense.receipt_url}
            />
          )}
        </CustomTableCell>
        <CustomTableCell align="left">
          <ApproveSwitch
            idToken={this.props.idToken}
            expenseId={expense.expense_id}
            adminApproveDate={expense.admin_approve_date}
          />
        </CustomTableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = state => {
  return {
    expenses: state.adminState.expenses
  };
};

export default connect(mapStateToProps)(UnapprovedExpensesTableRow);
