import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SelectEmployee from '../SelectEmployee';
import SelectYear from '../SelectYear';
import SelectMonth from '../SelectMonth';
import Currency from '../Currency';

import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  }
});

class ApproveExpensesHeader extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs>
              <div style={{ display: 'inline-flex', width: '100%', padding: 8, lineHeight: 5 }}>
                <SelectEmployee idToken={this.props.idToken} selectedEmployee={this.props.selectedEmployee} />
                <SelectMonth selectedMonth={this.props.selectedMonth} admin={true} />
                <SelectYear selectedYear={this.props.selectedYear} admin={true} />
              </div>
            </Grid>
            <Grid item xs>
            <div style={{ display: 'inline-flex', width: '100%', marginTop: '35px' }}>
              <Typography style={{ width: '100%', marginRight: 20 }} variant="h6" align="right" color="secondary">
                Current Balance: <Currency dollars={this.props.selectedEmployee.current_balance} />
              </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedEmployee: state.adminState.selectedEmployee,
    selectedYear: state.adminState.selectedYear,
    selectedMonth: state.adminState.selectedMonth,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ApproveExpensesHeader));
