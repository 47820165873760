import { GET_EMPLOYEE_REQUEST, GET_EMPLOYEE_SUCCESS, GET_EMPLOYEE_FAILURE } from '../actions/employee';
import { POST_EXPENSE_SUCCESS } from '../actions/expenses';

import { LOGOUT_SUCCESS } from '../actions/logout';
import { LOGIN_REQUEST } from '../actions/login';

const INITIAL_STATE = {
  employee: null,
  shouldReload: true,
  error: null
};

export default function employeeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_EMPLOYEE_REQUEST:
        return {
            ...state,
            shouldReload: false,
        };

    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employee: action.payload
      };

    case GET_EMPLOYEE_FAILURE:
      return {
        ...state,
        employee: null,
        error: action.payload.error.message
      };

    case LOGIN_REQUEST:
      return INITIAL_STATE;

    case LOGOUT_SUCCESS:
      return {
        ...state,
        employee: null,
      };

    // -------------------------------------------------

    case POST_EXPENSE_SUCCESS:
      return {
          ...state,
          shouldReload: true
      };

    // -------------------------------------------------

    default:
      return state;
  }
}
