import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import AddCreditForm from './AddCreditForm';

class AddCredit extends Component {
  render() {
    return (
      <div style={{ margin: '20px' }}>
        <Typography variant="h6" align="center">
          Enter a Credit
        </Typography>
        <AddCreditForm
          idToken={this.props.idToken}
          key="AddCreditForm"
        />
      </div>
    );
  }
}

export default AddCredit;
