export const Currency = ({ dollars }) => {
  const formatted = Number(dollars).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return formatted;
};

export default Currency;
