import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { getLogout } from '../../actions/logout';

class LogOutLink extends Component {
  logOut = event => {
    event.preventDefault();
    this.props.dispatch(getLogout());
  };

  render() {
    return (
      <Button color = 'inherit' onClick={this.logOut}>
        Log Out
      </Button>
    );
  }
}

export default connect()(LogOutLink);
