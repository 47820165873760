import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingPage from '../Landing';
import EmployeePage from '../Employee';
import AddExpensePage from '../AddExpense';
import AddCreditPage from '../AddCredit';
import ApprovePage from '../Approve';
import HelpPage from '../Help';
import * as ROUTES from '../../constants/routes';
import DownloadPage from "../Download";

const Routes = ({ idToken }) => {
  if (idToken) {
    return (
      <div>
        <Switch>
          <Route exact path={ROUTES.DOWNLOAD} render={props => <DownloadPage idToken={idToken} {...props} />} />
          <Route exact path={ROUTES.EMPLOYEE} render={props => <EmployeePage idToken={idToken} {...props} />} />
          <Route exact path={ROUTES.ADD_EXPENSE} render={props => <AddExpensePage idToken={idToken} {...props} />} />
          <Route exact path={ROUTES.ADD_CREDIT} render={props => <AddCreditPage idToken={idToken} {...props} />} />
          <Route exact path={ROUTES.APPROVE_EXPENSE} render={props => <ApprovePage idToken={idToken} {...props} />} />
          <Route exact path={ROUTES.HELP} component={HelpPage} />
        </Switch>
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path={ROUTES.HELP} component={HelpPage} />
      <Route path={ROUTES.LANDING} component={LandingPage} />
    </Switch>

  );
};

export default Routes;
