import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  headers: {
    margin: '25px 0px 0px 0px'
  },
  copy: {
    margin: '12px 5px 0px 5px',
    color: '#666'
  }
});

const Help = props => {
  const { classes } = props;

  return (
    <div style={{ margin: '44px' }}>
      <Typography variant="h4">Coin+Craft Guidelines</Typography>

      <div>
        <Typography variant="h5" className={classes.headers}>
          What's Coin+Craft?
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          Coin+Craft is a self-improvement budget for qualifying team members that can be used for professional and
          personal activities. Each team member has an annual budget of $1,500 (2 years/$3,000 max) to use towards
          re-energizing and learning new things. Common use cases include conferences at home and abroad, workshops,
          classes, hardware and other investments in health, mind and body.
        </Typography>

        <Typography variant="h5" className={classes.headers}>
          Who is eligible for Coin+Craft?
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          Full-time salaried team member in good standing. Sorry, no contractors, interns or part-timers. Your first
          year’s budget is determined by your start date:
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          STARTED BETWEEN <br />
          Jan-June: 100% <br />
          July-Oct: 50% <br />
          Nov-Dec: 0% <br />
          <span style={{ display: 'block', marginTop: '8px' }}>
            The budget amount is subject to change based on company performance factors.
          </span>
        </Typography>

        <Typography variant="h5" className={classes.headers}>
          C+C can be used in two ways
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          <span style={{ display: 'block', color: '#000' }}>PROFESSIONAL</span>
          Activities that can be classified as company benefits. Most anything related to software, design, technology,
          and doing your job will qualify.
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          <span style={{ display: 'block', color: '#000' }}>PERSONAL</span>
          Activities that cannot be classified as company benefits. You are choosing to take a “taxable income stipend”
          in lieu of reimbursement for a portion of or the total of your CC budget.
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          In short, activities that don’t qualify as company benefits will be taxed as additional cash in your paycheck.
          To be safe, always speak with a co-founder before any purchases are made.
        </Typography>

        <Typography variant="h5" className={classes.headers}>
          Where to send receipts
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          The newer mobile and web verisons of the Money app have the ability to submit photos of receipts as part of the expense submission, and in fact requires that you do so. Will McDowell handles all accounting and expense payment stuff, so contact him if you have any questions.
        </Typography>
      </div>

      <Typography variant="h4" style={{marginTop: '35px'}}>Business Expense Guidelines</Typography>

      <div>
        <Typography variant="h5" className={classes.headers}>
          Submit an Expense
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          Each expense needs to be approved by one of the expense approvers; either Nikki, Viki, or Will.
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          Expenses are calculated and checks are cut at the end of every month. Will McDowell will send out a reminder to
          everyone to submit their expenses, then you’ll get a printout with all of the expenses submitted that month.
          You’ll review for correctness, sign, and then you’ll get a paper check for your expense amount.
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          Note that if more than one person’s Coin+Craft expenses are on a single receipt, the person who paid for the
          combined expense will not get their money until everyone has submitted their C+C expense.
        </Typography>

        <Typography variant="h5" className={classes.headers}>
          Travel Expenses
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          Business travel and meals can be expensed through the same reimbursement process as above, with approval from
          one of the founders. As a general guideline, try to keep meals around $15 (dinner might be more, breakfast /
          lunch might be less). If the meal is for recruitment or with a client, then budget is less of a concern.
        </Typography>

        <Typography variant="body1" className={classes.copy}>
          If you are traveling for a client, we will follow their policy and get approval if needed for exceptions. You
          can review a client's specific policy within the Dropbox folder, "Client Travel Policies". If you need to
          coordinate travel or have a question about client travel policy, talk to Will McDowell.
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(Help);
