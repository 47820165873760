import {
  GET_EXPENSES_REQUEST,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES_FAILURE,
  POST_EXPENSE_REQUEST,
  POST_EXPENSE_SUCCESS,
  POST_EXPENSE_FAILURE,
  EMPLOYEE_SELECT_YEAR,
  EMPLOYEE_SELECT_MONTH
} from '../actions/expenses';

import { LOGOUT_SUCCESS } from '../actions/logout';
import {LOGIN_REQUEST} from "../actions/login";

const today = new Date();

const INITIAL_STATE = {
  selectedYear: today.getFullYear(),
  selectedMonth: 0,
  expenses: null,
  shouldReload: true,
  error: null
};

export default function expensesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_EXPENSES_REQUEST:
        return {
            ...state,
            shouldReload: false,
        };

    case GET_EXPENSES_SUCCESS:
      return {
        ...state,
        expenses: action.payload.resp.data
      };

    case GET_EXPENSES_FAILURE:
      return {
        ...state,
        expenses: null,
        error: action.payload.error
      };

    // -------------------------------------------------

    case LOGIN_REQUEST:
      return INITIAL_STATE;

    case LOGOUT_SUCCESS:
      return {
        ...state,
        expenses: null
      };

    // -------------------------------------------------

    case POST_EXPENSE_REQUEST:
      return {
        ...state
      };

    case POST_EXPENSE_SUCCESS:
      return {
        ...state,
        shouldReload: true
      };

    case POST_EXPENSE_FAILURE:
      return {
        ...state
      };

    // -------------------------------------------------

    case EMPLOYEE_SELECT_YEAR:
      return {
        ...state,
        selectedYear: action.payload
      };

    case EMPLOYEE_SELECT_MONTH:
      return {
        ...state,
        selectedMonth: action.payload
      };

    // -------------------------------------------------

    default:
      return state;
  }
}
