export const ERROR_SNACKBAR = 'ERROR_SNACKBAR';
export const SUCCESS_SNACKBAR = 'SUCCESS_SNACKBAR';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const createErrorSnackbar = message => ({
  type: 'ERROR_SNACKBAR',
  payload: {
    message: message,
    options: {
      variant: 'error',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    }
  }
});

export const createSuccessSnackbar = message => ({
  type: 'SUCCESS_SNACKBAR',
  payload: {
    message: message,
    options: {
      variant: 'success',
      autoHideDuration: 2000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
    }
  }
});

// ---------------------------------------------------------------------
// as per notistack demo:
// https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example
// ---------------------------------------------------------------------

export const enqueueSnackbar = notification => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification
  }
});

export const removeSnackbar = key => ({
  type: 'REMOVE_SNACKBAR',
  key
});
