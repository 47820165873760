export const LOADING = 'LOADING';

const INITIAL_STATE = {
  loading: true,
};

export default function loadingReducer(state = INITIAL_STATE, action) {
  const { type } = action;

  // updates do not trigger loading message, so ignore it
  if (type.includes('UPDATE')) {
      return state;
  }

  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE type of action, so we ignore it
  if (!matches) return state;

  const [, , phase] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    // and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    loading: phase === 'REQUEST'
  };
}
