import React, { Component } from 'react';
import EmployeeTable from './EmployeeTable';
import EmployeeHeader from './EmployeeHeader';
import Loading from '../Loading';

import { connect } from 'react-redux';
import { getExpenses } from '../../actions/expenses';
import { getEmployee } from '../../actions/employee';

class EmployeePage extends Component {
  componentDidMount() {
    this.loadEmployee();
    this.loadExpenses();
  }

  loadEmployee() {
    if (this.props.reloadEmployee) {
        this.props.dispatch(getEmployee(this.props.idToken));
    }
  }

  loadExpenses() {
    if (this.props.reloadExpenses) {
        this.props.dispatch(getExpenses(this.props.idToken));
    }
  }

  render() {
    if (!this.props.employee || !this.props.expenses) {
      return (
        <div>
          <Loading loadMessage="Loading employee info.." />
        </div>
      );
    }

    return (
      <div className="page">
        <EmployeeHeader />
        <EmployeeTable />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    employee: state.employeeState.employee,
    reloadEmployee: state.employeeState.shouldReload,
    expenses: state.expensesState.expenses,
    reloadExpenses: state.expensesState.shouldReload,
  };
};

export default connect(mapStateToProps)(EmployeePage);
