import React from 'react';
import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';

const TextEntry = props => {
  const { question, handleChange } = props;
  return (
    <div key={question.name} style={{ margin: '0px 0px 40px 5px' }}>
      {question.title}
      <div style={{ color: 'grey', fontSize: 'smaller', margin: '5px' }}>{question.info}</div>
      <TextField style={question.title.includes('Description') ? {width: '100%'} : {}} key={question.name} name={question.name} id={question.name} type="text" onChange={handleChange} />
      <ErrorMessage name={question.name}>{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
    </div>
  );
};

export default TextEntry;
