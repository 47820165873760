import {
    PROGRESS_START,
    PROGRESS_COMPLETE,

} from '../actions/progress';

const INITIAL_STATE = {
    progressCount: 0,
};

export default function progressReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROGRESS_START:
      return {
          progressCount: state.progressCount + 1,
      };

    case PROGRESS_COMPLETE:
      return {
          progressCount: Math.max(0, state.progressCount - 1),
      };

    default:
      return state;
  }
}
