import React from 'react';
import { ErrorMessage } from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const RadioButtonGroup = props => {
  const { question, values, disabled, handleChange } = props;

  return (
    <div key={question.name} style={{ margin: '0px 0px 40px 5px', color: disabled ? 'grey': '' } }>
      {question.title}
      <div style={{ color: 'grey', fontSize: 'smaller', margin: '5px' }}>{question.info}</div>
      <RadioGroup row name={question.name}
      >
        {question.options.map(option => {
          return (
            <FormControlLabel
              control={<Radio checked={!disabled && values[question.name] === option.value} />}
              key={option.label}
              name={question.name}
              value={option.value}
              disabled={disabled}
              label={option.label}
              checked={values[question.name] === option.value}
              onChange={handleChange}
            />
          );
        })}
      </RadioGroup>
      <ErrorMessage name={question.name}>{msg => <div className="error error-message">{msg}</div>}</ErrorMessage>
    </div>
  );
};

export default RadioButtonGroup;
