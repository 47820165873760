import { createErrorSnackbar } from './snackbar';
import FirebaseInstance from '../components/Firebase';
import { progressStart, progressComplete } from './progress';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const getLoginRequest = () => ({
  type: LOGIN_REQUEST
});

export const getLoginSuccess = resp => ({
  type: LOGIN_SUCCESS,
});

export const getLoginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: { error }
});

export const getLogin = () => {
  return async dispatch => {
      dispatch(progressStart());
      dispatch(getLoginRequest());
      await FirebaseInstance.doLogIn()
          .then(authUser => {
              if (authUser) {
                  dispatch(getLoginSuccess());
                  dispatch(progressComplete());
              } else {
                  dispatch(progressComplete());
                  dispatch(getLoginFailure('No auth user'));
                  dispatch(createErrorSnackbar('No auth user'));
              }
          })
          .catch(error => {
              dispatch(progressComplete());
              dispatch(getLoginFailure(error));
              dispatch(createErrorSnackbar('Login Failed'));
          });
  };
};
